import DropDownMenu_ShinyMenuIButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/ShinyMenuIButtonSkin/ShinyMenuIButtonSkin.skin';
import DropDownMenu_ShinyMenuIButtonSkinController from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_ShinyMenuIButtonSkin = {
  component: DropDownMenu_ShinyMenuIButtonSkinComponent,
  controller: DropDownMenu_ShinyMenuIButtonSkinController
};


export const components = {
  ['DropDownMenu_ShinyMenuIButtonSkin']: DropDownMenu_ShinyMenuIButtonSkin
};

